/* @font-face { */
/*   font-family: bladeRunnerEng; */
/*   src: url(./fonts/blade_runner_eng.TTF); */
/*   font-weight: bold; */
/* } */

/* @font-face { */
/*   font-family: xoloniumBold; */
/*   src: url(./fonts/Xolonium-Bold.otf); */
/*   font-weight: bold; */
/* } */


body {
  margin: 0;
  /* background-color: #000f4a; */
  width: 100%;
  /* position: relative; */
  /* background-color: white; */
  /* font-size: 10px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .bRE { */
/*   font-family: bladeRunnerEng; */
/*   color: #9400d3; */
/*   font-size: 1em; */
/*   height: 100%; */
/*   line-height: 100%; */
/*   display: flex; */
/*   align-items: center; */
/*   justify-content: center; */
/* } */

/* .xB { */
/*   color: black; */
/*   font-size: 1.5em; */
/*   padding: 10px 10px; */
/*   background-color: white; */
/*   width: 8em; */
/*   font-family: xoloniumBold; */
/*   text-align: center; */
/* } */
