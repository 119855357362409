@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');


@font-face {
  font-family: Xolonium-Bold;
  src: url(./fonts/Xolonium-Bold.otf);
  font-weight: normal; }

@font-face {
  font-family: bladeRunner;
  src: url(./fonts/blade-runner-rus.ttf);
  font-weight: bold; }

.headerGlobalContainer {
  width: 100%;
  height: 415px;
  background: linear-gradient(#0847cb, #000000); }

$family-runner: 'bladeRunner';

.reactTitleContainer {
  position: absolute;
  margin-top: 40px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 415px;
  div {
    color: red;
    font-size: 2em;
    font-family: $family-runner !important;
    span {
      color: white; } } }

.headerLocalContainer {
  display: flex !important;
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 415px;
  background-image: radial-gradient(rgba(255,255,255,0.2) 15%, transparent 15%);
  background-position: 0 0, 50px 50px;
  background-size: 5px 5px;
  width: 100%;
  color: white;
  margin: 0px !important;
  padding: 0px !important; }


$black: #000000;
$white: #FFFFFF;
$red: #ff0000;
// Set your brand colors
// box customization
$box-color: #000f4a;
$box-background-color: #d8d8d8;
$box-shadow: 0 0.5em 1.5em -0.125em rgba($white, 1), 0 0px 0 1px rgba($white, 1);
$purple: #8a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;

$navbar-background-color: #9400d3;
$navbar-item-color: white;


// Update Bulma's global variables
$family-sans-serif: 'Nunito', sans-serif;
$family-secondary: 'Xolonium-Bold';
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $purple;
$link: $pink;
$widescreen-enabled: true;
$fullhd-enabled: true;

// Update some of Bulma's component variables
$body-background-color: $beige-lighter;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

// custom
$navbar-item-hover-background-color: black;
$navbar-item-hover-color: white;
// $navbar-burger-color: white

// footer
$footer-background-color: black;
$footer-padding: 10px 10px 10px;
$footer-color: white;


// Import only what you need from Bulma
@import "../../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../../node_modules/bulma/sass/base/_all.sass";
@import "../../../node_modules/bulma/sass/elements/_all";
@import "../../../node_modules/bulma/sass/form/_all.sass";
@import "../../../node_modules/bulma/sass/components/_all";
@import "../../../node_modules/bulma/sass/grid/_all";
@import "../../../node_modules/bulma/sass/layout/_all";
@import "../../../node_modules/bulma/sass/helpers/_all";

@mixin back-pos($pos) {
  background-size: cover;
  background-position: $pos; }

$mobile: 768;

.headerLocalContainer > div:nth-of-type(2) {
  background: url('./cyberpunk-city.png');
  @include back-pos(right);
  @include from($tablet) {
    min-width: 680px; }
  @include until($mobile) {
    width: 100%; } }


.headerLocalContainer > div:nth-of-type(3) {
  @include from($tablet) {
    width: 50%; } }

.headerLocalContainer > div:nth-of-type(4) {
  background: url('./spaceship.png');
  @include back-pos(right);
  @include from($desktop) {
    min-width: 600px; } }

html, body {
  margin: 0;
  padding: 0;
  width: auto;
  overflow-x: hidden;
  background-color: #000f4a; }

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  border: 5px solid red; }

.appBody {
  width: 100% !important;
  position: relative;
  min-height: calc(100vh - 52px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .miniBody {
    width: 100%;
    padding: 20px;
    background-image: url('mainBg.png');
    background-size: 336px 663px;
    background-repeat: no-repeat;
    background-position: right; }


  .foot {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: auto; }

  .loader-wrapper {
    width: 100%;
    position: absolute;
    top: 70%;
    display: flex;
    justify-content: center;
    align-items: center;


    .loader {
      height: 20px;
      width: 20px; }


    &.is-active {
        opacity: 1;
        z-index: 1; } } }


